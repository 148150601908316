import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import banCiao0 from '../assets/images/IMG_7880.jpg'
import banCiao1 from '../assets/images/IMG_7881.jpg'
import banCiao2 from '../assets/images/IMG_7882.jpg'
import banCiao3 from '../assets/images/IMG_7883.jpg'
import banCiao4 from '../assets/images/IMG_7884.jpg'
import banCiao5 from '../assets/images/IMG_7885.jpg'
import banCiao6 from '../assets/images/IMG_7886.jpg'
import banCiao7 from '../assets/images/IMG_7887.jpg'
import banCiao8 from '../assets/images/IMG_7888.jpg'
import banCiao9 from '../assets/images/IMG_7889.jpg'
import banCiao10 from '../assets/images/pri.jpeg'

import googleMap from '../assets/images/google-maps.png'

const BanCiao = props => (
  <Layout>
    <Helmet>
      <title>新北板橋健身房｜三健客3musclers健身俱樂部</title>
      <meta name="description" content="三健客新北板橋健身房分館，有多元的健身以及體能訓練課程，場館內也有專業品牌ROGUE、ELEIKO、CYBEX的健身品牌設備，推薦不論是重訓、體態雕塑、肌力體能訓練等運動需求，三健客都可以給您完善的健身計劃及課程，帶領您一起達成自己的理體態。" />
      <meta name="keywords" content="三健客,健身房,板橋健身房,健身房推薦,健身俱樂部,健身房課程,私人健身房,教練課程,健身房教練,團體課程" />
      <link rel="canonical" href="https://www.3musclers.com/banCiao" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>三健客板橋店</h1>
          </header>
          <span className="image main">
            <img src={banCiao0} alt="3musclers" />
          </span>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image main">
                  <img src={banCiao1} alt="3musclers" />
                </span>
              </div>
              <div className="col-4" s>
                <span className="image main">
                  <img src={banCiao2} alt="3musclers" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={banCiao3} alt="3musclers" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={banCiao4} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={banCiao5} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={banCiao6} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={banCiao7} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img
                    src={banCiao8}
                    alt=""
                    style={{ width: '100%', height: '60%' }}
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img
                    src={banCiao9}
                    alt=""
                    style={{ width: '100%', height: '60%' }}
                  />
                </span>
              </div>
            </div>
          </div>
          <h3 style={{ color: '#fcce10' }}>💪 器材</h3>
          <p>
            除了一般機械式器材精挑細選符合生物力學角度的 LIFE
            FITNESS、CYBEX不說；三健客健身房採用的槓鈴、槓片皆選用美國品牌ROGUE，另外還設有十台深蹲架、三台臥推架、兩個ELEIKO舉重平台，以及ELEIKO比賽架......滿滿溢出的
            都是我們對自由重量的熱情。
          </p>
          <p>
            另外我們也設有多功能草皮訓練區、雪橇、龍門架、農夫走路槓……就是希望滿足大家功能性訓練的需求，讓鍛鍊身體除了健康、好看，也要能運用在提升生活品質上。
          </p>
          <h3 style={{ color: '#fcce10' }}>🍴飲食休息區</h3>
          <p>
            座位舒適，也設有健身餐點販賣機以及微波爐，讓渴望控制飲食卻又沒時間備餐的你，輕鬆獲得所需營養。
          </p>
          <h3 style={{ color: '#fcce10' }}>💦 淋浴間</h3>
          <p>在運動後舒舒服服沖個熱水澡，真的是最舒暢了！</p>
          <h3 style={{ color: '#fcce10' }}>💎 VIP教練課教室</h3>
          <p>
            對於健身初學者或是想更加精進自己的健客們，我們也設有VIP教室區；透明卻獨立的環境，帶給學生們的是最舒適與高雅的上課體驗。
          </p>
          <h3 style={{ color: '#fcce10' }}>👃 空氣清淨機</h3>
          <p>
            在這150坪的空間裡，我們備有10台空氣清淨機，絕不利用你乾淨的肺來過濾髒空氣；健身房的清新，由我們守護！
          </p>
          <h3 style={{ color: '#fcce10' }}>
            👨‍⚕ Tanita 塔尼達 日本健康管理專家 MC-980MA體組成計
          </h3>
          <p>
            最後，我們最狂的器材來了，要價接近90萬的塔尼達體組成計，快速、準確、還能把報告印回家；就是要讓大家可以隨時監測自己的身體狀況與訓練成效，一步一步達成自己的理想體態！
          </p>
          <p>這麼優質的環境，你還在等什麼？</p>
          <h3 style={{ color: '#fcce10' }}>收費方式</h3>
          <span className="image left">
            {/* <img
              src={banCiao10}
              alt=""
              style={{ width: '100%', height: '60%' }}
            /> */}
          </span>
          <p>
            另有提供場地租借200元起
            歡迎私訊、電話或來店諮詢報名一對一或團體課程！
            <br /> 電話：02-8258-8025
            {/* <div
              class="fb-messengermessageus"
              messenger_app_id="3212226998840909"
              page_id="107493774239485"
              color="white"
              size="standard"
            ></div> */}
            <br />
            地址：
            <a
              href="https://goo.gl/maps/2NJbg4ox6gW3BQR78"
              style={{ textDecoration: 'none' }}
              target="_blank"
            >
              新北市板橋區莒光路211號b1
              <img src={googleMap} style={{ width: '24px' }} />
            </a>
          </p>
          <p>
            <iframe
              src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2F%25E4%25B8%2589%25E5%2581%25A5%25E5%25AE%25A2-3musclers-%25E6%259D%25BF%25E6%25A9%258B%25E5%25BA%2597-125554492170293%2F&width=450&layout=standard&action=like&size=large&share=true&height=35&appId=3212226998840909"
              width="450"
              height="35"
              style={{border:'none',overflow:'hidden'}}
              scrolling="no"
              frameborder="0"
              allowTransparency="true"
              allow="encrypted-media"
            ></iframe>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/E_gvYUWjEvQ"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </p>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/%E4%B8%89%E5%81%A5%E5%AE%A2-3musclers-%E6%9D%BF%E6%A9%8B%E5%BA%97-125554492170293/"
                className="icon alt fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/3musclers/?hl=zh-tw"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
)

export default BanCiao
